<template>
  <div class="vx-col w-full mb-base">
    <swiper class="h-full section" :options="swiperOption" ref="slider">
      <swiper-slide class="h-full" v-for="statisticKey in statisticsKeys(resourceType, resourceUuid)" :key="statisticKey + resourceUuid" >
        <statistic-card
          class="vx-col w-full mb-base h-full"
          :key="statisticKey"
          :resource-type="resourceType"
          :resource-uuid="resourceUuid"
          :statistic-key="statisticKey"
        />
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

  import StatisticCard from "@/modules/Statistics/Components/StatisticCard";
  import { mapGetters } from "vuex";
  import i18n from "../../../i18n";
  import {fetchStatistics} from "@/modules/Statistics/Services/StatisticsService";

  export default {
    components: {
      StatisticCard
    },
    props: {
      resourceType: String,
      resourceUuid: String,
    },
    data () {
      const self = this;

      return {
        componentKey: 0,
        swiperOption: {
          autoplay: {
            delay: 15000,
            disableOnInteraction: true
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet(index, className) {
              const statisticsKeys = self.statisticsKeys(self.resourceType, self.resourceUuid)

              if (statisticsKeys.length === 1) return ''

              const statistic = self.$store.getters['statistics/statistic'](statisticsKeys[index], self.resourceType, self.resourceUuid)[0]
              const translationKey = statistic.statistic ? statistic.statistic.key : statistic.accumulated.key

              return `<div class="${className} swiper-pagination-bullet-custom px-2 rounded-full">
                    <span class="uppercase hidden sm:hidden md:hidden lg:hidden xl:inline-block">${ i18n.t(translationKey) }</span>
                  </div>`
            },
          },
          allowTouchMove: false
        }
      }
    },
    computed: {
      ...mapGetters("statistics", ["statisticsKeys", "statistic"]),
    },

    async created() {
      for await (let statisticKey of this.statisticsKeys(this.resourceType, this.resourceUuid)) {
        await fetchStatistics(statisticKey, this.resourceType, this.resourceUuid)
      }
    }

  };
</script>

<style lang="scss">

.section .swiper-pagination {
  text-align: right !important;
  padding-right: 15px !important;
}

.section .swiper-pagination-bullets {
  bottom: 94% !important;
}

.swiper-pagination-bullet-custom {
  font-size: 10px;
  $size: 20px;
  width: auto !important;
  height: $size !important;
  line-height: $size !important;
  text-align: center;
  opacity: 0.7;
  transition: all 0.3s;
  border-radius: 50px !important;
  cursor: pointer;
  color: white;

  &:hover {
    opacity: 1;
  }

  &.swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
}

</style>
