var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-col w-full mb-base" },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "h-full section",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._l(
            _vm.statisticsKeys(_vm.resourceType, _vm.resourceUuid),
            function(statisticKey) {
              return _c(
                "swiper-slide",
                { key: statisticKey + _vm.resourceUuid, staticClass: "h-full" },
                [
                  _c("statistic-card", {
                    key: statisticKey,
                    staticClass: "vx-col w-full mb-base h-full",
                    attrs: {
                      "resource-type": _vm.resourceType,
                      "resource-uuid": _vm.resourceUuid,
                      "statistic-key": statisticKey
                    }
                  })
                ],
                1
              )
            }
          ),
          _c("div", {
            staticClass: "swiper-pagination swiper-pagination-bullets",
            attrs: { slot: "pagination" },
            slot: "pagination"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }